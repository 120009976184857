<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="content">
        <p class="title">La page est introuvable</p>
        <router-link
          class="button is-large is-link is-light is-fullwidth"
          to="/"
          >Acceuil</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.content {
  display: block;
  margin: auto;
}
</style>